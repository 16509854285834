import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    email: Yup.string().email('Введите корректный email.').required('Поле обязательно для заполнения.'),
    apiKey: Yup.string().min(10, 'Слишком короткий ключ').required('Поле обязательно для заполнения.')
})

export default function CloudFlareForm() {
    const { values, handleChange, errors } = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Название профиля</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    name="email"
                    type="email"
                    value={ values.email }
                    onChange={ handleChange }
                    placeholder="Например: example@mail.com"
                    isInvalid={ !!errors.email }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="apiKey">
                <Form.Label>Ключ api</Form.Label>
                <Form.Control
                    name="apiKey"
                    value={ values.apiKey }
                    onChange={ handleChange }
                    type="text"
                    placeholder="Например: d249b90ceef68f9087c5b5efaca3957935b70"
                    isInvalid={ !!errors.apiKey }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.apiKey }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}