import {useFormikContext} from "formik";
import SwitchableInput from "../../components/form/SwitchableInput";
import {Form} from "react-bootstrap";
import * as Yup from "yup";
import registrars from "../Registrars/registrars.json"

export const initialFieldValues = {
    cloudflareApiKey: '',
    cloudflareEmail: '',
    cloudflareId: '',
    serverId: '',
    serverIp: '',
    registrarId: '',
    domains: '',
    serverManualInput: false,
    cloudflareManualInput: false,
    useAutoRedirect: true,
    isRemoveOldRecords: true,
    useHttps: true
}

export const validationSchema = Yup.object({})

function DnsUpdateForm({cloudflareProfile, serverProfile, registrarProfile}) {
    const {values, setFieldValue, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <SwitchableInput
                mainTitle="Аккаунт Cloudflare"
                onSwitch={(value) => setFieldValue('cloudflareManualInput', value)}
                formInput={<>
                    <Form.Group className="mb-3" controlId="cloudflareEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            name="cloudflareEmail"
                            type="email"
                            value={values.cloudflareEmail}
                            onChange={handleChange}
                            placeholder="Например: example@mail.com"
                            isInvalid={!!errors.cloudflareEmail}
                            autoFocus
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cloudflareEmail}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="cloudflareApiKey">
                        <Form.Label>Ключ api</Form.Label>
                        <Form.Control
                            name="cloudflareApiKey"
                            value={values.cloudflareApiKey}
                            onChange={handleChange}
                            type="text"
                            placeholder="Например: d249b90ceef68f9087c5b5efaca3957935b70"
                            isInvalid={!!errors.cloudflareApiKey}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cloudflareApiKey}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>}
                formSelect={<>
                        <Form.Select
                            name="cloudflareId"
                            value={values.cloudflareId}
                            onChange={handleChange}
                            isInvalid={!!errors.cloudflareId}
                            aria-label="Выберите аккаунт CloudFlare"
                        >
                            <option value="">Выберите профиль CloudFlare</option>
                            {cloudflareProfile.length > 0 ? cloudflareProfile.map(cloudflare => (
                                <option key={cloudflare.id}
                                        value={cloudflare.id}>{cloudflare.title} ({cloudflare.email})</option>
                            )) : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.cloudflareId}
                        </Form.Control.Feedback>
                    </>}
            />

            <SwitchableInput
                mainTitle="Ip адрес"
                onSwitch={(value) => setFieldValue('serverManualInput', value)}
                formInput={<>
                    <Form.Group className="mb-3" controlId="serverIp">
                        <Form.Control
                            name="serverIp"
                            type="text"
                            value={values.serverIp}
                            onChange={handleChange}
                            placeholder="Например: 85.82.83.84"
                            isInvalid={!!errors.serverIp}
                            autoFocus
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.serverIp}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>}
                formSelect={<>
                    <Form.Select
                        name="serverId"
                        value={values.serverId}
                        onChange={handleChange}
                        isInvalid={!!errors.serverId}
                        aria-label="Выберите профиль сервера"
                    >
                        <option value="">Выберите профиль Сервера</option>
                        {serverProfile.length > 0 ? serverProfile.map(server => (
                            <option key={server.id} value={server.id}>{server.title} ({server.ipAddress})</option>
                        )) : ''}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.serverId}
                    </Form.Control.Feedback>
                </>}
            />

            <Form.Group className="mb-3" controlId="registrarId">
                <Form.Label>Регистратор (не обязательно)</Form.Label>
                <Form.Select
                    name="registrarId"
                    value={values.registrarId}
                    onChange={ handleChange }
                    isInvalid={ !!errors.registrarId }
                    aria-label="Выберите регистратора"
                >
                    <option value="">Выберите профиль регистратора</option>
                    {registrarProfile.length > 0 ? registrarProfile.map(registrar => (
                        <option key={registrar.id} value={registrar.id}>{registrar.title} ({registrars[registrar.registrarId].title})</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.registrarId }
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="domains">
                <Form.Label>Домен или домены списком (Каждый домен с новой строки)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="domains"
                    rows={5}
                    placeholder={`example1.com\nexample2.com\nexample3.com`}
                    value={values.domains}
                    onChange={handleChange}
                    type="text"
                    isInvalid={!!errors.domains}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.domains}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="useHttps">
                <Form.Check
                    type="switch"
                    name="useHttps"
                    label="Всегда использовать HTTPS"
                    checked={values.useHttps}
                    onChange={(event) => {
                        setFieldValue('useHttps', event.target.checked)
                    }}
                    isInvalid={!!errors.useHttps}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="useAutoRedirect">
                <Form.Check
                    type="switch"
                    name="useAutoRedirect"
                    label="Включить автоматический редирект на https"
                    checked={values.useAutoRedirect}
                    onChange={(event) => {
                        setFieldValue('useAutoRedirect', event.target.checked)
                    }}
                    isInvalid={!!errors.useAutoRedirect}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="isRemoveOldRecords">
                <Form.Check
                    type="switch"
                    name="isRemoveOldRecords"
                    label="Искать и удалять старые NS записи"
                    checked={values.isRemoveOldRecords}
                    onChange={(event) => {
                        setFieldValue('isRemoveOldRecords', event.target.checked)
                    }}
                    isInvalid={!!errors.isRemoveOldRecords}
                />
            </Form.Group>
        </Form>
    )
}

export default DnsUpdateForm