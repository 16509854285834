import {Tab, Col, Row, Stack, ButtonGroup} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import SitesNav from "./SitesNav";
import ButtonNote from "./components/ButtonNote";
import SitesNote from "./SitesNote";
import ApiWrapperContextProvider, {ApiWrapperContext} from "../../context/ApiWrapperContext";
import {ApiAddButton, ApiConfirmModalDialog} from "../../components/ApiWrapper";
import ButtonEdit from "../../components/buttons/ButtonEdit";
import ButtonDelete from "../../components/buttons/ButtonDelete";
import ModalContextProvider, {ModalContext} from "../../context/ModalContext";
import SitesAddForm, {validationSchema} from "./SitesAddForm";
import SitesBackupDomains from "./BackupDomains/SitesBackupDomains";
import SitesList from "./List";
import ApiModalFormWrapper from "../../components/ApiWrapper/ApiModalFormWrapper";
import ApiManager from "../../api";

function Sites() {

    const [isShowNote, setIsShowNote] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [cloudflareProfile, setCloudflareProfile] = useState(null)
    const {editItem, deleteItem} = useContext(ApiWrapperContext)
    const {openModal} = useContext(ModalContext);

    function showNote() {
        setIsShowNote(!isShowNote)
    }

    const handleEdit = (item, modalId) => {
        editItem(item);
        if (modalId) {
            openModal(modalId)
        }
    };

    const handleConfirmDelete = (item, modalId) => {
        deleteItem(item);
        if (modalId) {
            openModal(modalId)
        }
    };

    useEffect(() => {
        new ApiManager()
            .request('cloudflare', {
                paginator: 'without'
            })
            .then(data => {
                setCloudflareProfile(data)
            })
    }, [])

    return (
        <>
            <ApiAddButton size="sm" variant="outline-dark" modalId="modalWithForm">Добавить оффер</ApiAddButton>
            <hr/>
            <Tab.Container id="left-tabs-example" activeKey={`nav_${currentCategory?.id}`}>
                <Row>
                    <Col xs={12} md={2}>
                        <SitesNav onNavSelect={(nav) => setCurrentCategory(nav)}/>
                    </Col>
                    <Col xs={12} md={10}>
                        <Tab.Content>
                            {!currentCategory?.id ? 'Загрузка...' : <Tab.Pane eventKey={`nav_${currentCategory.id}`}>
                                <Stack gap={3}>
                                    <Col>
                                        <ButtonGroup className="mb-2">
                                            <ButtonNote onClick={showNote}/>
                                            <ButtonEdit onClick={() => handleEdit(currentCategory, 'modalWithForm')}/>
                                            <ButtonDelete onClick={() => handleConfirmDelete(currentCategory, 'modalConfirm')}/>
                                        </ButtonGroup>
                                    </Col>
                                    <SitesNote show={isShowNote}>{currentCategory.note}</SitesNote>
                                    <ApiWrapperContextProvider route='sites.sites' routeParams={{
                                        categoryId: currentCategory.id,
                                        size: 10
                                    }}>
                                        <ModalContextProvider>
                                                <SitesList cloudflareProfile={cloudflareProfile}/>
                                        </ModalContextProvider>
                                    </ApiWrapperContextProvider>
                                    <ApiWrapperContextProvider route='sites.backup-domain' routeParams={{
                                        categoryId: currentCategory.id,
                                        size: 10
                                    }}>
                                        <ModalContextProvider>
                                            <SitesBackupDomains cloudflareProfile={cloudflareProfile}/>
                                        </ModalContextProvider>
                                    </ApiWrapperContextProvider>
                                </Stack>
                            </Tab.Pane>}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

            <ApiModalFormWrapper windowId="modalWithForm" validationSchema={validationSchema} title="Изменение категории сайтов">
                <SitesAddForm/>
            </ApiModalFormWrapper>

            <ApiConfirmModalDialog windowId="modalConfirm">
                <h3 className="text-center">Вы уверены что хотите удалить данный оффер?</h3>
                <p className="text-center text-danger">Будут так же удалены все сайты и резерные домены привязанные к данному офферу.</p>
            </ApiConfirmModalDialog>
        </>
    );
}

export default Sites;
