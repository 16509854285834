import {ButtonGroup, Card} from "react-bootstrap";
import {ApiAddButton, ApiConfirmModalDialog, ApiPaginator, ApiWrapper} from "../../../components/ApiWrapper";
import ButtonEdit from "../../../components/buttons/ButtonEdit";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import SitesBackupDomainsForm, {validationSchema} from "./SitesBackupDomainsForm";
import {useContext, useEffect, useState} from "react";
import ApiManager from "../../../api";
import {ApiWrapperContext} from "../../../context/ApiWrapperContext";
import registrars from "../../Registrars/registrars.json"
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";
import styles from "../SiteCellBackground.module.css";
import DataGridWrapper from "../../../components/DataGridWrapper";

export default function SitesBackupDomains({cloudflareProfile}) {

    const {route} = useContext(ApiWrapperContext)
    const [registrarProfile, setRegistrarProfile] = useState(null)

    function printRegistrar(id) {
        if (!registrarProfile) {
            return '-';
        }

        const registrar = registrarProfile?.filter(data => data.id === id).shift();
        return registrar ? `${registrar?.title} (${registrars[registrar?.registrarId].title})` : '-'
    }

    function printCloudflare(id) {
        if (!cloudflareProfile) {
            return '-';
        }

        const cloudflare = cloudflareProfile?.filter(data => data.id === id).shift();
        return cloudflare ? `${cloudflare?.title} (${cloudflare.email})` : '-'
    }

    function getColumns({handleEdit, handleConfirmDelete}) {
        return [
            {
                key: 'domain',
                name: 'Домен'
            },
            {
                key: 'registrarId',
                name: 'Регистратор',
                renderCell({row}) {
                    return printRegistrar(row.registrarId);
                }
            },
            {
                key: 'cloudflareId',
                name: 'Cloudflare',
                renderCell({row}) {
                    return printCloudflare(row.cloudflareId);
                }
            },
            {
                key: 'action',
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return (
                        <ButtonGroup className="mb-2">
                            <ButtonEdit onClick={() => handleEdit(row, 'backupDomainModalForm')}/>
                            <ButtonDelete
                                onClick={() => handleConfirmDelete(row, 'backupDomainModalConfirm')}/>
                        </ButtonGroup>
                    )
                }
            }
        ]
    }

    useEffect(() => {
        const apiManager = new ApiManager();

        apiManager
            .request('registrar', {
                paginator: 'without'
            })
            .then(data => {
                setRegistrarProfile(data)
            })

    }, [route])

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex justify-content-between">
                        Резервные домены на замену
                        <ApiAddButton size="sm" variant="outline-dark"
                                      modalId="backupDomainModalForm">Добавить</ApiAddButton>
                    </div>
                </Card.Title>
                <Card.Text>
                    <ApiWrapper>
                        {(props) => <DataGridWrapper
                            defaultColDef={{flex: 1}}
                            columns={getColumns(props)}
                            rows={props.fetchedData.items}
                        />}
                    </ApiWrapper>
                    <ApiPaginator size="sm"/>
                </Card.Text>
            </Card.Body>
            <ApiModalFormWrapper windowId="backupDomainModalForm" validationSchema={validationSchema}
                                 title="Изменение резервного домена">
                <SitesBackupDomainsForm
                    cloudflareProfile={cloudflareProfile}
                    registrars={registrars}
                    registrarProfile={registrarProfile}
                />
            </ApiModalFormWrapper>

            <ApiConfirmModalDialog windowId="backupDomainModalConfirm">
                <h3 className="text-center">Вы уверены что хотите удалить резервный домен?</h3>
            </ApiConfirmModalDialog>
        </Card>
    )
}
