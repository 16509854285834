import {Button, Modal} from "react-bootstrap";
import {useContext, useEffect} from "react";
import {ModalContext} from "../../../context/ModalContext";
import 'react-data-grid/lib/styles.css';
import '../DisableBorder.css';
import DataGrid from "react-data-grid";
import {formatDateTime} from "../../../utils/helper";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";
import {ApiPaginator, ApiWrapper} from "../../../components/ApiWrapper";

export default function SitesListHistoryModal({windowId, siteId, ...rest}) {
    const {isOpen, closeModal, windowId: currentWindowId} = useContext(ModalContext);
    const onClose = () => closeModal(windowId);
    const gridStyle = {
        minHeight: 400,
        blockSize: "100%"
    }

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none')
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none')
                }
            }
        })
    })

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
            },
            {
                key: 'cloudflareEmail',
                name: 'Cloudflare Email',
            },
            {
                key: 'registrarName',
                name: 'Профиль регистратора',
            },
            {
                key: 'note',
                name: 'Примечание',
            },
            {
                key: 'transferAt',
                name: 'Дата переезда',
                renderCell({row}) {
                    return formatDateTime(row['transferAt'])
                }
            }
        ];
    }

    return (
        <Modal {...rest}
               show={currentWindowId === windowId ? isOpen : false}
               onHide={() => closeModal(windowId)}
               fullscreen={true}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    История переездов
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApiWrapperContextProvider route='sites.site.history' routeParams={{siteId}}>
                    <ApiWrapper>
                        {(props) => <DataGrid
                            style={gridStyle}
                            columns={getColumns(props)}
                            rows={props.fetchedData.items}
                        />}
                    </ApiWrapper>
                    <ApiPaginator size="sm"/>
                </ApiWrapperContextProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
}
